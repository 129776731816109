.App {
  text-align: center;
  background-image: linear-gradient(
          135deg,
          #003366 0%,
          #4d4d4f 89%
  ) !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-image: linear-gradient(
    135deg,
    #003366 0%,
    #00b2e3 69%,
    #38b6ff 89%
  ) !important; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
